<template>
  <Row class="expand-row">
    <Col span="24">
      <div :id="row.id" style="width: 100%; height: 300px"></div>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "BwstressTableExpand",
  props: {
    row: Object
  },
  data() {
    return {
      myChart: {},
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.myChart = this.$echarts.init(document.getElementById(this.row.id));
      let label = ["2021-07-01", "2021-07-02", "2021-07-03", "2021-07-04", "2021-07-05", "2021-07-06", "2021-07-07", "2021-07-08"]
      let upload = [233, 233, 3000, 180, 199, 233, 210, 180]
      let download = [333, 555, 400, 2000, 199, 555, 336, 118]

      let option = {
        backgroundColor: "#101e44",
        legend: {
          show: true,
          textStyle: {
            color: "#7ec7ff",
            fontWeight : 'bold',
          }
        },
        grid: {
          left: 10,
          right: 22,
          top: 15,
          bottom: 5,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(255,255,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(255,255,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(255,255,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
        },
        xAxis: [{
          type: "category",
          boundaryGap: false,
          axisLabel: {
            formatter: '{value}',
            fontSize:14,
            margin:20,
            textStyle: {
              color: "#7ec7ff"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#243753"
            }
          },
          splitLine: {
            show:true,
            lineStyle: {
              color: "#243753"
            }
          },
          axisTick:{
            show:false
          },
          data: label
        }],
        yAxis: [
          {
            boundaryGap:false,
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#7ec7ff"
              }
            },
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              lineHeight: 40
            },
            splitLine: {
              lineStyle: {
                color: "#243753"
              }
            },
            axisLine: {
              show: true,
              lineStyle:{
                color:"#283352"
              }
            },
            // axisTick: {
            //   show: false
            // }
          }
        ],
        series: [{
          name: "下行带宽",
          type: "line",
          smooth: true,
          showSymbol: true,
          symbolSize: 3,
          symbol:'circle',
          zlevel: 3,
          itemStyle:{
            color:'#19df79',
            borderColor:"#a3c8d8"
          },
          lineStyle: {
            normal: {
              width:1,
              color: "#19df79",
            }
          },
          areaStyle: {
            normal: {
              color: this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [{
                    offset: 0,
                    color: "rgba(88,255,255,0.2)"
                  },
                    {
                      offset: 0.8,
                      color: "rgba(88,255,255,0)"
                    }
                  ],
                  false
              ),
            }
          },
          data: download
        },
          {
            name: "上行带宽",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbolSize: 3,
            symbol:'circle',
            zlevel: 3,
            itemStyle:{
              color:'#19a3df',
              borderColor:"#a3c8d8"
            },
            lineStyle: {
              normal: {
                width:1,
                color: "#19a3df",
              }
            },
            areaStyle: {
              normal: {
                color: this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [{
                      offset: 0,
                      color: "rgba(88,255,255,0.2)"
                    },
                      {
                        offset: 0.8,
                        color: "rgba(88,255,255,0)"
                      }
                    ],
                    false
                ),
              }
            },
            data: upload
          }]
      };
      this.myChart.setOption(option);
    })
  }
}
</script>

<style scoped>
.expand-row{
  margin-bottom: 16px;
}
</style>